import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body pb-0" }
const _hoisted_3 = { class: "d-flex flex-column justify-content-between h-100" }
const _hoisted_4 = { class: "pt-15 mb-10" }
const _hoisted_5 = { class: "text-dark text-center fs-1 fw-bolder lh-lg" }
const _hoisted_6 = { class: "text-center text-gray-600 fs-6 fw-bold pt-4 pb-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: "flex-grow-1 bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom card-rounded-bottom h-200px",
            style: _normalizeStyle({
                backgroundImage: 'url(' + _ctx.getLogoPath() + ')',
              })
          }, null, 4),
          _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.$t("TITLE")), 1),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t("SUBTILTE")), 1)
        ])
      ])
    ])
  ]))
}